<template>
    <div class="col-md-12">
        <div class="card card-container">
            <img
                id="profile-img"
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                class="profile-img-card"
            />
            <Form @submit="handleRegister" :validation-schema="schema">
                <div v-if="!successful">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <Field
                            name="email"
                            type="email"
                            class="form-control"
                            autocomplete="on"
                        />
                        <ErrorMessage name="email" class="error-feedback" />
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <Field
                            name="password"
                            type="password"
                            class="form-control"
                            autocomplete="on"
                        />
                        <ErrorMessage name="password" class="error-feedback" />
                    </div>
                    <div class="form-group">
                        <label for="password2">Confirm Password</label>
                        <Field
                            name="password2"
                            type="password"
                            class="form-control"
                            autocomplete="on"
                        />
                        <ErrorMessage name="password2" class="error-feedback" />
                    </div>

                    <div class="form-group">
                        <button
                            class="btn btn-base btn-block"
                            :disabled="loading"
                        >
                            <span
                                v-show="loading"
                                class="spinner-border spinner-border-sm"
                            ></span>
                            Sign Up
                        </button>
                    </div>
                    <a
                        href="/login"
                        class="btn btn-duo btn-block"
                        :disabled="loading"
                        ><span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                        ></span
                        >Login</a
                    >
                </div>
            </Form>
            <button
                @click="$router.push('/forgot-password')"
                class="btn btn-default center-block"
            >
                Forgot Password
            </button>
            <div
                v-if="message"
                class="alert"
                :class="successful ? 'alert-success' : 'alert-danger'"
            >
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useMeta } from "vue-meta";
import * as yup from "yup";

export default {
    setup() {
        useMeta({
            title: "Senatus | Register",
            description: "Register to Senatus.world",
        });
    },
    name: "BoardRegister",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            email: yup
                .string()
                .required("Email is required!")
                .email("Email is invalid!")
                .max(50, "Must be maximum 50 characters!"),
            password: yup
                .string()
                .required("Password is required!")
                .min(6, "Must be at least 6 characters!")
                .max(40, "Must be maximum 40 characters!"),
            password2: yup
                .string()
                .required("Password is required!")
                .min(6, "Must be at least 6 characters!")
                .max(40, "Must be maximum 40 characters!"),
        });

        return {
            successful: false,
            loading: false,
            message: "",
            schema,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push("/profile");
        }
    },
    methods: {
        handleRegister(user) {
            this.message = "";
            this.successful = false;
            this.loading = true;

            this.$store.dispatch("auth/register", user).then(
                (data) => {
                    this.message = data.message;
                    this.successful = true;
                    this.loading = false;
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.successful = false;
                    this.loading = false;
                }
            );
        },
    },
};
</script>

<style scoped>
label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.error-feedback {
    color: red;
}
</style>
