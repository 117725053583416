<template>
    <metainfo />
    <header />
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-light navbar-base">
            <a href="/" class="navbar-brand"><span class="h3">Senatus</span></a>
            <button
                class="navbar-toggler navbar-toggler-right collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span> </span>
                <span> </span>
                <span> </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link"
                            ><router-link to="/home" class="nav-link">
                                <font-awesome-icon icon="home" /> Home
                            </router-link>
                            <span class="sr-only">(current)</span></a
                        >
                    </li>
                    <li v-if="showAdminBoard" class="nav-item">
                        <a class="nav-link"
                            ><router-link to="/home" class="nav-link">
                                <font-awesome-icon icon="home" /> Home
                            </router-link></a
                        >
                    </li>
                    <li v-if="showModeratorBoard" class="nav-item">
                        <a class="nav-link"
                            ><router-link to="/mod" class="nav-link"
                                ><font-awesome-icon
                                    icon="circle-user"
                                />Moderator Board</router-link
                            ></a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                            ><router-link
                                v-if="currentUser"
                                to="/user"
                                class="nav-link"
                                ><font-awesome-icon
                                    icon="circle-user"
                                />User</router-link
                            ></a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                            ><router-link to="/surveys" class="nav-link"
                                ><font-awesome-icon
                                    icon="landmark"
                                />Survey</router-link
                            ></a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                            ><router-link to="/post" class="nav-link"
                                ><font-awesome-icon
                                    icon="clipboard-check"
                                />Create</router-link
                            ></a
                        >
                    </li>
                </ul>

                <div v-if="!currentUser" class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                        <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            About Us
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Team</a>
                            <a class="dropdown-item" href="#">Contact Us</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#"
                                >Something else here</a
                            >
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link to="/register" class="nav-link">
                            <font-awesome-icon icon="user-plus" /> Sign Up
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/login" class="nav-link">
                            <font-awesome-icon icon="sign-in-alt" /> Login
                        </router-link>
                    </li>
                </div>
                <div v-if="currentUser" class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                        <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            About Us
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Team</a>
                            <a class="dropdown-item" href="#">Contact Us</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#"
                                >Terms and disclosures</a
                            >
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link to="#" class="nav-link">
                            <font-awesome-icon icon="star" />
                            Points:{{
                                currentUser.points > 0 ? currentUser.points : 0
                            }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/profile" class="nav-link">
                            <font-awesome-icon icon="user" />
                            {{ currentUser.email }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click.prevent="logOut">
                            <font-awesome-icon icon="sign-out-alt" /> LogOut
                        </a>
                    </li>
                </div>
            </div>
        </nav>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
    setup() {
        useMeta({
            title: "Senatus | Opinions, stats and stuff",
            description:
                "Ask questions, Answer surveys and get rewarded for it. Senatus is peoples forum where you can get a grasp of what masses are thinking. Join us to know more",
            htmlAttrs: { lang: "en", amp: true },
        });
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        showAdminBoard() {
            if (this.currentUser && this.currentUser["roles"]) {
                return this.currentUser["roles"].includes("ROLE_ADMIN");
            }

            return false;
        },
        showModeratorBoard() {
            if (this.currentUser && this.currentUser["roles"]) {
                return this.currentUser["roles"].includes("ROLE_MODERATOR");
            }

            return false;
        },
    },
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    },
};
</script>
<style>
.nav-item {
    position: relative;
}

.nav-item:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    margin: 0 0;
    background-color: black; /*Follow your text color*/
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.nav-item:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
    display: block;
    background-color: #444;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    -webkit-transition: transform 0.35s ease-in-out;
    -moz-transition: transform 0.35s ease-in-out;
    -o-transition: transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: relative;
    left: 0px;
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: relative;
    top: -12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}
</style>
