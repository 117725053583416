import axios from 'axios';
import authHeader from './auth-header';

const BASE_URL = process.env.VUE_APP_BASEURL

const API_URL_OG = BASE_URL+ '/api-path/surveys/';
const API_URL_DONE = BASE_URL + '/api-path/surveys_done/';
const API_URL = BASE_URL + '/api-path/survey_data/';
const API_URL_USER = BASE_URL + '/api-path/users/'

class UserService {
  getAllSurveys(pg) {
    return axios.get(API_URL_OG + '?page=' + pg  , { headers: authHeader() });
  }

  getSingleSurvey(slug) {
    return axios.get(API_URL_OG +  slug  , { headers: authHeader() });
  }

  getUserBoard() {
    return axios.get(API_URL + '', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL_OG + '', { headers: authHeader() });
  }

  getQuestion(pg) {
    return axios.get(API_URL + 'question/' + pg + '/', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL_OG + '', { headers: authHeader() });
  }

  getSurveyWithID(id) {
    return axios.get(API_URL_OG + id + '/'  , { headers: authHeader() });
  }

  getAnswersForQuestion(id) {
    return axios.get(API_URL + 'results/' + id + '/', { headers: authHeader() });
  }

  getAllSurveysDone(pg) {
    return axios.get(API_URL_DONE + '?page=' + pg  , { headers: authHeader() });
  }

  getUserInfo(id) {
    return axios.get(API_URL_USER  + id + '/' , { headers: authHeader() });
  }

  updateUserInfo(id, user_data) {
    var data = new FormData();

    data.append('email', user_data.email);
    data.append('age', user_data.age);
    if(user_data.gender) data.append('gender', user_data.gender);
    data.append('weight', user_data.weight);
    data.append('height', user_data.height);
    if(user_data.education) data.append('education', user_data.education);
    if(user_data.income) data.append('income', user_data.income);
    if(user_data.birthdate) data.append('birthdate', user_data.birthdate);
    if(user_data.ethnicity) data.append('ethnicity', user_data.ethnicity);
    data.append('phonenumber', user_data.phonenumber);
    if(user_data.country) data.append('country', user_data.country);
    data.append('state', user_data.state);
    data.append('zip_code', user_data.zip_code);
    if(user_data.political) data.append('political', user_data.political);
    return axios
      .patch(API_URL_USER  + id + '/' , (
        data
      ), { headers: authHeader() })
      .then(response => {
          //Error check here
        return response;
      });
  }

  createSurvey(name, description, category) {
    var data = new FormData();
    data.append('name', name);
    data.append('description', description);
    if(category) data.append('category', category);
    return axios
      .post(API_URL_OG , (
        data
      ), { headers: authHeader() })
      .then(response => {
          //Error check here
        return response;
      });
  }

  addQuestion(survey_id, text, choices, type, required) {
    var data = new FormData();
    data.append('text', text);
    data.append('required', required);
    data.append('survey', survey_id);
    data.append('choices', choices);
    data.append('type', type);
    return axios
      .post(API_URL + 'question/', (
        data
      ), { headers: authHeader() })
      .then(response => {
          //Error check here
        return response;
      });
  }

  createSurveryResponse(survey_id, user_id) {
    var data = new FormData();
    data.append('survey', survey_id);
    data.append('user', user_id);
    return axios
      .post(API_URL + 'user_response/', (
        data
      ), { headers: authHeader() })
      .then(response => {
          //Error check here
        return response;
      });
  }

  submitAnswers(question_id, response_id, body) {
    var data = new FormData();
    data.append('question', question_id);
    data.append('response', response_id);
    data.append('body', body);
    return axios
      .post(API_URL + 'answer/', (
        data
      ), { headers: authHeader() })
      .then(response => {
          //Error check here
        return response;
      });
  }


  
}

export default new UserService();