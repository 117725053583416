import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faStar,
  faHands,
  faLandmark,
  faCircleUser,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faCircleUser, faHands, faClipboardCheck,faUserPlus, faSignInAlt, faSignOutAlt, faStar, faLandmark);
export { FontAwesomeIcon };