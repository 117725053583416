<template>
    <div class="container">
        <div v-if="showSurveys">
            <div class="jumbotron jumbotron-fluid">
                <div class="container">
                    <h1 class="display-4 text-center">
                        Curated<small class="text-muted">
                            Question for You</small
                        >
                    </h1>
                </div>
            </div>
            <div
                class="card-group"
                deck
                v-for="results in content.results"
                :key="results"
            >
                <div
                    bg-variant="dark"
                    :header="results.name"
                    class="card card-2 text-left w-35"
                    role="button"
                >
                    <a
                        v-on:click="answerQuestions(results)"
                        class="stretched-link"
                    >
                        <div class="row">
                            <div class="col-md-4">
                                <div v-if="results.category">
                                    <img
                                        :src="
                                            require(`@/assets/surveyCategories/${results.category}.svg`)
                                        "
                                        class="card-img"
                                        width="100"
                                        height="100"
                                        alt="..."
                                    />
                                </div>
                                <div v-else>
                                    <img
                                        :src="
                                            require(`@/assets/surveyCategories/17.svg`)
                                        "
                                        width="100"
                                        height="100"
                                        class="card-img"
                                        alt="..."
                                    />
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-header">
                                    <h2 class="card-title">
                                        {{ results.name }}
                                    </h2>
                                </div>
                                <div class="card-body">
                                    <div class="card-text">
                                        {{ results.description }}
                                    </div>
                                </div>
                                <footer class="blockquote-footer">
                                    Category -
                                    <cite title="Source Title">{{
                                        getCategory(results.category)
                                    }}</cite>
                                </footer>
                            </div>

                            <div class="col-md-1">
                                <img :src="require(`@/assets/right.svg`)"
                                width="100" height="100" class="card-img"
                                alt="..." cursor: pointer />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="questions.length">
                <div class="card">
                    <div class="card-header d-flex">
                        <div class="mr-auto">
                            <div
                                class="btn btn-base"
                                @click="showCloseConfirmation"
                            >
                                X
                            </div>
                        </div>
                        <div class="ml-auto">
                            <div class="btn btn-duo" @click="shareURL()">
                                Share
                            </div>
                        </div>
                    </div>
                    <flow-form
                        v-on:complete="onComplete"
                        v-on:submit="onSubmit"
                        v-bind:questions="questions"
                        v-bind:language="language"
                    />
                </div>
            </div>
            <div v-else>
                <div class="jumbotron">
                    <h1 class="display-2 text-center">
                        <img
                            :src="require(`@/assets/cone.svg`)"
                            width="100"
                            height="100"
                            class="card-img"
                            alt="..."
                        />Survey<small class="text-muted"> Not ready yet</small>
                    </h1>
                    <h4 class="text-center">
                        <cite title="Source Title">Come back later</cite>
                        <div class="card-header x-icon">
                            <div
                                class="btn btn-base"
                                @click="showCloseConfirmation"
                            >
                                X
                            </div>
                        </div>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMeta } from "vue-meta";
import UserService from "../services/user.service";
import {
    FlowForm,
    QuestionModel,
    ChoiceOption,
    QuestionType,
    LanguageModel,
} from "@ditdot-dev/vue-flow-form";

export default {
    setup() {
        useMeta({
            title: "Senatus | Surveys",
            description:
                "Available Surveys for You. Answer Surveys to earn points",
        });
    },
    name: "BoardSurveys",
    components: { FlowForm },
    data() {
        return {
            content: "",
            loading: false,
            count: 1,
            language: new LanguageModel({
                // Your language definitions here (optional).
                // You can leave out this prop if you want to use the default definitions.
            }),
            questions: [],
            answer_choices: [],
            showSurveys: true,
            picked: "One",
            errorMessage: "",
            surveyId: "",
            surveySlug: "",
            responseId: "",
            pages: 0,
            counter: 0,
        };
    },
    beforeMount() {
        UserService.getAllSurveys(this.count).then(
            (response) => {
                this.content = response.data;
                this.count++;
                this.pages =
                    this.content.count / 5 + (this.content.count % 5 ? 1 : 0);
            },
            (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    },
    mounted() {
        window.onscroll = () => {
            let bottomOfWindow =
                document.documentElement.scrollTop + window.innerHeight ===
                document.documentElement.offsetHeight;
            if (bottomOfWindow) {
                if (this.content && this.pages >= this.count) {
                    UserService.getAllSurveys(this.count++).then(
                        (response) => {
                            //this.content = response.data;

                            for (
                                var j = 0;
                                j < response.data.results.length;
                                j++
                            ) {
                                this.content.results.push(
                                    response.data.results[j]
                                );
                            }
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            }
        };
    },
    methods: {
        answerQuestions(surveyDetails) {
            this.surveyId = surveyDetails.id;
            this.surveySlug = surveyDetails.slug;
            this.questions = [];
            for (var i = 0; i < surveyDetails.questions.length; i++) {
                UserService.getQuestion(surveyDetails.questions[i]).then(
                    (response) => {
                        this.questions.push(
                            new QuestionModel({
                                title: response.data["text"],
                                type:
                                    response.data["type"] === "radio" ||
                                    response.data["type"] === "select"
                                        ? QuestionType.MultipleChoice
                                        : QuestionType.Text,
                                options: [],
                                value: response.data.id,
                            })
                        );

                        for (var j = 0; j < response.data.choices.length; j++) {
                            this.questions[
                                this.questions.length - 1
                            ].options.push(
                                new ChoiceOption({
                                    label: response.data.choices[j],
                                })
                            );
                        }
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
            this.showSurveys = false;
        },
        getCategory(number) {
            var cats = [
                "Art & Design",
                "Business",
                "Educational",
                "Entertainment",
                "Food & Drink",
                "Games",
                "General",
                "History",
                "Literature",
                "Movies",
                "Music",
                "Nature",
                "Science",
                "Sports",
                "Tech",
                "TV",
                "The World",
                "Other",
            ];
            if (!number) return "Other";
            return cats[number];
        },
        shareURL() {
            var copy = window.location.origin + "/s/" + this.surveySlug;
            navigator.clipboard.writeText(copy);
            this.$swal.fire({
                icon: "success",
                title: "Text copied to clipboard",
                text: copy,
                showConfirmButton: false,
                timer: 3000,
            });
        },
        showAlert(success) {
            // Use sweetalert2
            if (success)
                this.$swal.fire({
                    icon: "success",
                    text: "Thank you, taking you to results!",
                });
            else
                this.$swal.fire({
                    icon: "error",
                    text: "Already Submitted, check results!",
                });
        },
        showCloseConfirmation() {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, close it!",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.showSurveys = true;
                    }
                });
        },
        onSubmit(questionList) {
            // Create response first
            UserService.createSurveryResponse(
                this.surveyId,
                this.$store.state.auth.user.id
            ).then(
                (response) => {
                    this.responseId = response.data["id"];
                    //this.counter = 0;
                    //Add answers to it

                    for (var i = 0; i < this.questions.length; i++) {
                        UserService.submitAnswers(
                            questionList[i].value,
                            this.responseId,
                            questionList[i].answer
                        ).then(
                            (response) => {
                                if (!response) console.log("dummy");
                                this.counter++;
                                if (this.counter >= this.questions.length) {
                                    this.loading = false;
                                    this.showSurveys = true;
                                    this.counter = 0;
                                    this.showAlert(true);
                                    this.$router.push(
                                        "/results/" + this.surveySlug
                                    );
                                }
                            },
                            (error) => {
                                this.errorMessage =
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.loading = false;
                                this.showAlert(false);
                                this.$router.push(
                                    "/results/" + this.surveySlug
                                );
                            }
                        );
                    }
                },
                (error) => {
                    console.log(error);
                    this.errorMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.loading = false;
                }
            );
        },
        onComplete(completed, questionList) {
            console.log(questionList);
        },
        handleSubmitAnswers() {
            this.loading = true;
            if (
                this.questions.length != Object.keys(this.answer_choices).length
            ) {
                this.loading = false;
                this.errorMessage =
                    "Answer all the Questions before Submitting";
            } else {
                this.errorMessage = "";
                // Create response first
                UserService.createSurveryResponse(
                    this.surveyId,
                    this.$store.state.auth.user.id
                ).then(
                    (response) => {
                        this.responseId = response.data["id"];
                        //this.counter = 0;
                        //Add answers to it
                        for (var i = 0; i < this.questions.length; i++) {
                            UserService.submitAnswers(
                                this.lick[i].id,
                                this.responseId,
                                this.answer_choices[i]
                            ).then(
                                (response) => {
                                    console.log(response.data);
                                    this.counter++;
                                    if (this.counter >= this.questions.length) {
                                        this.loading = false;
                                        this.showSurveys = true;
                                        this.counter = 0;
                                        this.showAlert(true);
                                        this.$router.push(
                                            "/results/" + this.surveySlug
                                        );
                                    }
                                },
                                (error) => {
                                    this.errorMessage =
                                        (error.response &&
                                            error.response.data &&
                                            error.response.data.message) ||
                                        error.message ||
                                        error.toString();
                                    this.loading = false;
                                    this.showAlert(false);
                                    this.$router.push(
                                        "/results/" + this.surveySlug
                                    );
                                }
                            );
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.errorMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.loading = false;
                    }
                );
            }
        },
    },
};
</script>
<style>
/* Import Vue Flow Form base CSS */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
/* Import one of the Vue Flow Form CSS themes (optional) */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css'; */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css";

.card-2 {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
        0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    padding: 14px 80px 18px 36px;
    cursor: pointer;
}

.card-2:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>
