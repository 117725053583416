import axios from 'axios';
import authHeader from './auth-header';

const BASE_URL = process.env.VUE_APP_BASEURL

const API_URL = BASE_URL + '/api-path/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'customToken/', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  passwordReset(email) {
    return axios
      .post(API_URL + 'password_reset/', {
        email: email,
      })
      .then(response => {
        return response.data;
      });
  }

  passwordConfirmChange(token, password) {
    return axios
      .post(API_URL + 'password_reset/confirm/', {
        token: token,
        password: password,
      })
      .then(response => {
        return response.data;
      });
  }

  passwordChange(password) {
    return axios
      .put(API_URL + 'change-password/', {
        headers: authHeader(),
        password: password,
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'register/', {
      email: user.email,
      password: user.password,
      password2: user.password2
    });
  }
}

export default new AuthService();