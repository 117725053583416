import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Surveys from "./components/Surveys.vue"
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const SurveyResults = () => import("./components/SurveyResults.vue")
const PostQuestions = () => import("./components/PostQuestions.vue")
const SurveySingle = () => import("./components/SurveySingle.vue")
const ForgotPassword = () => import("./components/ForgotPassword.vue")
const ChangePassword = () => import("./components/ChangePassword.vue")
const NotFound = () => import("./components/NotFound.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { guest: true },
  },
  {
    path: "/home",
    component: Home,
    meta: { guest: true },
  },
  {
    path: "/surveys",
    component: Surveys,
    meta: {requiresAuth: true},
  },
  {
    path: "/s/:slug",
    component: SurveySingle,
    meta: { guest: true },
  },
  {
    path: "/login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/register",
    component: Register,
    meta: { guest: true },
    
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
    meta: {requiresAuth: true},
  },
  {
    path: "/post",
    name: "post",
    // lazy-loaded
    component: PostQuestions,
    meta: { guest: true },
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
    meta: {requiresAuth: true},
  },
  {
    path: "/results/:slug",
    name: "results",
    // lazy-loaded
    component: SurveyResults,
    meta: { guest: true },
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
    meta: {requiresAuth: true},
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
    meta: {requiresAuth: true},
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    // lazy-loaded
    component: ForgotPassword,
    meta: {requiresAuth: false},
  },
  {
    path: "/change-password/:token",
    name: "change-password",
    // lazy-loaded
    component: ChangePassword,
    meta: {requiresAuth: false},
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not Found',
    component: NotFound,
    meta: {requiresAuth: false},
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user')) {
      next()
      return
    }
    next('/login')
  } 
  else {
    next()
  }
});


export default router;