<template>
    <div class="container">
        <div class="card mb-3 w-75">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img
                        :src="require('@/assets/stats.svg')"
                        class="card-img"
                        alt="..."
                    />
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h2 class="card-title">Get Opinions</h2>
                        <p class="card-text">
                            This is the place to go wild. Ask! Ask any question
                            to a targeted demographic. Post a question and
                            specify the target group, or not. Get insight about
                            your questions within few days.
                        </p>
                        <a href="/post" class="btn btn-base btn-block">Ask</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 w-75">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img
                        :src="require('@/assets/gift.svg')"
                        class="card-img"
                        alt="..."
                    />
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h2 class="card-title">Answer Questions</h2>
                        <p class="card-text">
                            Check what questions are trending, collect points
                            for answering paid questions. Join discussions and
                            tell everyone that your opinion is the right one.
                        </p>
                        <a href="/surveys" class="btn btn-base btn-block"
                            >Play</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 w-75">
            <div class="row no-gutters">
                <div class="col-md-4">
                    <img
                        :src="require('@/assets/bank.svg')"
                        class="card-img"
                        alt="..."
                    />
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h2 class="card-title">Personal</h2>
                        <p class="card-text">
                            This is your personal space to do whatever you want.
                            Especially if you want to redeem your points. You
                            can redeem your points into gift cards or cash. View
                            results for your posted questions here as well.
                        </p>
                        <p class="card-text">
                            <small class="text-muted"
                                >(Redeem functionality unavailable in
                                beta)</small
                            >
                        </p>
                        <a href="#" class="btn btn-base btn-block">Go</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BoardHome",
    data() {
        return {
            content: "",
        };
    },
    mounted() {},
};
</script>
<style>
.card--flex .btn {
    padding: 0px;
    display: flex;
}
</style>
